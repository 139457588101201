.content {
  background: black;
  padding: 16px;
  font-size: 16px;
  color: white;
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: Terminal_Vector;
}

.content > img {
  margin: auto;
}

.cols-2 {
  display: flex;
}

.cols-2 > .left,
.cols-2 > .right {
  flex: 1;
}

.contact-link-container,
.projects-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.contact-link {
  font-size: 28px;
  flex-grow: 1;
}

.contact-link > a > i {
  font-size: 70px;
  width: 90px;
  padding-left: 10px;
}

.contact-link > a > span {
  position: absolute;
  margin-top: 32px;
}

.proj-middle {
  margin-top: 20px;
  font-size: 16px;
}

.proj-bottom {
  margin-top: 20px;
}

.proj-bottom > div {
  margin: 4px;
}

@media (max-width: 700px) {
  .content > img {
    width: 180px;
  }

  .cols-2 {
    display: block;
  }

  .right {
    margin-top: 20px;
  }
}
