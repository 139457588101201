#snake-grid {
  border: 1px solid;
  height: 100%;
  margin: 0px 80px;
  display: flex;
  flex-direction: column;
}

#snake-grid:focus {
  outline: none;
}

.row {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.cell {
  flex: 1;
}

.cell.food {
  background-size: cover;
  background-image: url('../assets/apple.png');
}

.cell.body {
  background-color: green;
  background-image: none;
}

.score {
  margin: auto;
  margin-top: -12px;
  margin-bottom: 4px;
}
