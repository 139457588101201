.top-bar {
  padding: 2px 0px 2px 8px;
  margin: 3px 3px 0px 3px;
  background: #1b007d; /* Old browsers */
  background: -moz-linear-gradient(left, #1b007d 18%, #0081d1 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #1b007d 18%,#0081d1 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #1b007d 18%,#0081d1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  color: white;
  cursor: grab;
  display: flex;
}

.top-bar:active {
  cursor: grabbing;
}

.top-bar > .name {
  flex: 1;
  margin-top: 2px;
}

.top-bar > .min-btn {
  background: #BFBFBF;
  color: black;
  font-size: 12px;
  box-sizing: border-box;
  padding-bottom: 1px;
  width: 20px;
  margin: 2px 4px;
  text-align: center;
  cursor: pointer;
}

.content-container {
  flex: 1;
  border: 3px solid #BFBFBF;
  display: flex;
  flex-direction: column;
}

.box {
  width: 600px;
  height: 450px;
  background: #BFBFBF;
  display: flex;
  flex-direction: column;
  position: absolute;
}

.box.hide {
  visibility: hidden;
}

.box:nth-of-type(4) {
  left: 48px;
  top: 48px;
}

.box:nth-of-type(3) {
  left: 88px;
  top: 88px;
}

.box:nth-of-type(2){
  left: 128px;
  top: 128px;
}

.box:nth-of-type(1){
  left: 168px;
  top: 168px;
}

@media (max-width: 700px) {
  .box {
    display: block;
    position: initial;
    width: 95vw;
    height: initial;
    margin-bottom: 8px;
  }

  .box:nth-of-type(5) {
    margin-bottom: 60px;
  }

  .min-btn {
    display: none;
  }

  .content-container {
    min-height: 306px;
  }
}
